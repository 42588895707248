import _ from 'lodash'
import ContentProvider from './components/ContentProvider';
import ContentConsumer from './components/ContentConsumer';
import Cookies from 'js-cookie'
import envSettings from '../globals/envSettings'

export const SUPPORTED_LOCALES = ['es', 'en', 'it']
export const DEFAULT_LOCALE = 'es'
const SUPPORTED_LR_LOCALES = {
    es: 'es_ES',
    en: 'en_GB',
    it: 'it_IT'
}
const LOCALE_COOKIE = 'LOCALE_COOKIE'

export {
    ContentProvider,
    ContentConsumer
};

export const PLACEHOLDERS = {
    LOCALE: 'LOCALE',
    LINK_URL: 'LINK_URL',
    SCHOOL: 'SCHOOL',
    THIRD_PARTY: 'THIRD_PARTY',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    NAME: 'NAME',
    DATE: 'DATE'
}

export function getLRLocale(language) {
    return SUPPORTED_LR_LOCALES[getLocale(language)]
}

export function getShortLocale(locale) {
  if(SUPPORTED_LOCALES.find(item => item === locale)) return locale
  return _.get(_.invert(SUPPORTED_LR_LOCALES), locale, undefined)
}

export function getLocale(language) {
    let locale = validateLanguage(language)
    if (locale) return locale

    locale = validateLanguage(getPathLocale())
    if (locale) return locale

    locale = validateLanguage(Cookies.get(LOCALE_COOKIE))
    if (locale) return locale

    locale = validateLanguage((window.navigator.languages && window.navigator.languages[0]) || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage)
    return locale ? locale : DEFAULT_LOCALE
}

export function validateLanguage(language) {
    if (language)
	      return SUPPORTED_LOCALES.find(item => language.substring(0,2).toLowerCase()===item)
    return null
}

export function getPathLocale() {
    const locale = window.location.pathname && window.location.pathname.length > 1 && window.location.pathname.split('/')[1]
    return locale ? SUPPORTED_LOCALES.concat(Object.values(SUPPORTED_LR_LOCALES)).find(item => locale === item) : null
}

export function interpolate(text, placeholder, value) {
    return text.replace(placeholder, value)
}

export function changeLocaleCookie(locale) {
  Cookies.set(LOCALE_COOKIE, locale, {
    expires: 365, path: '/',
    secure: true,
    domain: envSettings.DOMAIN.slice(envSettings.DOMAIN.indexOf('.'))
  })
}

// >>MBQ MARCH 2025 Cookies Consent Notice
export function changeCookiesNoticeLanguage(locale) {
    // https://developer.onetrust.com/onetrust/docs/javascript-api
    //OneTrust.changeLanguage("en")
    if(window.OneTrust != null) {
        window.OneTrust.changeLanguage(locale)
    }
}
// MBQ MARCH 2025 Cookies Consent Notice<<
