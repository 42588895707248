// MBQ MARCH 2025 Cookies Consent Notice
import React from 'react'
import { Helmet } from 'react-helmet'
import { withContent } from '../../utils/hocs'

const HtmlHead = (props) => {
  const { locale } = props

  return (
    <Helmet htmlAttributes={{ lang: locale }} />
  )
}

export default withContent(HtmlHead)
